<script>
import Swal from 'sweetalert2'

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "conversion",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "conversion",
      items: [
        {
          text: "Menu",
        },
        {
          text: "conversion",
          active: true,
        },
      ],
      errors: [],
      conversion: {},
      conversions: [],
      moyens: [],
      managers: [],
      exchanges: [],
      gain: "",
      from_id: "",
      to_id: "",
      manager_id: "",
      exchange_id: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('conversions'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.conversions = data
        this.doesLoadingOpen = false
      })
    },
    loadMoyens() {
      fetch(Helper.route('moyens'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        console.log("moyens ",data);
        this.moyens = data
      })
    },
    loadExchanges() {
      fetch(Helper.route('exchanges'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.exchanges = data
        this.doesLoadingOpen = false
      })
    },
    loadManagers() {
      fetch(Helper.route('users/roles/MANAGER'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        this.managers = data
        console.log("managers ",this.managers);
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let body = {
        "gain": parseFloat(this.gain),
        "from": {
          "id": this.from_id
        },
        "to": {
          "id": this.to_id
        },
        "exchange": {
          "id": this.exchange_id
        },
        "manager": {
          "id": this.manager_id
        }
      }
      console.log(body);
      fetch(Helper.route('conversions'), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        
        this.loading = false
        if (this.status == 201) {
          this.doesAddOpen = false
          this.conversions.push(data) 
          Swal.fire("Conversion ajouter !", "La conversion a été enregistrée avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "conversion_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let body = {
        "gain": parseFloat(this.gain),
        "status": true,
        "from": {
          "id": this.from_id
        },
        "to": {
          "id": this.to_id
        },
        "exchange": {
          "id": this.exchange_id
        },
        "manager": {
          "id": this.manager_id ?? "0"
        }
      }
      fetch(Helper.route('conversions/'+this.conversion.id), Helper.requestOptionsJSON("PUT", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.conversions = this.conversions.map(i => i.id !== this.conversion.id ? i : data)
          Swal.fire("Conversion modifier !", "La conversion a été modifiée avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "conversion_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('conversions/'+this.conversion.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        if (this.status == 200) {
          this.conversions = this.conversions.filter(i => i.id !== this.conversion.id)
          this.loading = false
          this.conversion = null
          this.doesDeleteOpen = false
          Swal.fire("La conversion supprimer !", "La conversion a été supprimée avec succès.", "success");
        } else {
          Swal.fire("La conversion non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    initItem(conversion, action="edit") {
      this.conversion = conversion
      this.initData(conversion)
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    },
    initData(item = null) {
      this.gain = item != null ? item.gain : ""
      this.to_id = item != null ? item?.to?.id : ""
      this.from_id = item != null ? item?.from?.id : ""
      this.manager_id = item != null ? item.manager?.id : ""
      this.exchange_id = item != null ? item.exchange?.id : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadMoyens()
    this.loadManagers()
    this.loadExchanges()
    this.doesLoadingOpen = false
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      if (!this.doesAddOpen) {
        this.initData(null)
      }
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.initData(null)
        this.conversion = null
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.initData(null)
        this.conversion = null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3" v-show="status != 403">
      <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
        <i class="bx bx-plus font-size-16 align-middle me-2"></i>
        Ajouter un nouveau
      </b-button>
    </div>

    <div class="row" v-show="status != 403">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des conversions</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>conversions</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Moyen From</th>
                    <th>Moyen To</th>
                    <th>Echange</th>
                    <th>Gain</th>
                    <th>Manager</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(conversion, i) in conversions" :key="conversion.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ conversion?.from?.name }}</td>
                    <td>{{ conversion?.to?.name }}</td> 
                    <td>{{ conversion?.exchange?.from?.name+" à "+conversion?.exchange?.to?.name }}</td> 
                    <td>{{ conversion.gain }}</td>
                    <td>{{ conversion.manager?.last_name?? " "}} {{ conversion.manager?.first_name??" "  }}</td>
                    <td class="hstack">
                      <b-button class="btn-sm ms-2" @click="initItem(conversion, 'edit')" variant="success">
                        <i class="bx bx-edit font-size-16 align-middle mx-1"></i>
                      </b-button>
                      <b-button variant="danger" @click="initItem(conversion, 'delete')" class="ms-2 btn-sm">
                        <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
    </div>


    <div v-show="status === 403">
      <h3 class="text-danger text-center my-5">Réserver à l'administrateur principal</h3>
    </div>

    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

    <!-- ADD MODAL -->
    <b-modal v-model="doesAddOpen" id="modal-center" centered size="md" title="Ajouter un conversion"
      title-class="font-18" hide-footer>
      <form @submit.prevent="addItem()">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="">Moyen From</label>
            <select required v-model="from_id" class="form-control">
              <option value=""></option>
              <template v-for="from in moyens" :key="from.id">
                <option :value="from.id">{{ from.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Moyen To</label>
            <select required :disabled="from_id == ''" v-model="to_id" class="form-control">
              <option value=""></option>
              <template v-for="to in moyens" :key="to.id">
                <option v-if="to.id != from_id" :value="to.id">{{ to.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Manager</label>
            <select v-model="manager_id" class="form-control">
              <option value="0"></option>
              <template v-for="manager in managers" :key="manager.id">
                <option :value="manager.id">{{ manager.last_name+" "+manager.first_name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Echange</label>
            <select v-model="exchange_id" class="form-control">
              <option value="0"></option>
              <template v-for="exchange in exchanges" :key="exchange.id">
                <option :value="exchange.id">{{ exchange.from.name+" à "+exchange.to.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Gain</label>
            <input type="text" v-model="gain" class="form-control">
          </div>
        </div>
        <div class="mt-3" v-if="!loading">
          <input type="submit" value="Enregistrer" class="btn btn-primary">
        </div>
        <div class="mt-3 text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal v-model="doesEditOpen" id="modal-center" centered size="md" title="Modifier un conversion"
      title-class="font-18" hide-footer>
      <form @submit.prevent="editItem()">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="">Moyen From</label>
            <select disabled required v-model="from_id" class="form-control">
              <option value=""></option>
              <template v-for="from in moyens" :key="from.id">
                <option :value="from.id">{{ from.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Moyen To</label>
            <select required disabled v-model="to_id" class="form-control">
              <option value=""></option>
              <template v-for="to in moyens" :key="to.id">
                <option v-if="to.id != from_id" :value="to.id">{{ to.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Manager</label>
            <select v-model="manager_id" class="form-control">
              <option value="0"></option>
              <template v-for="manager in managers" :key="manager.id">
                <option :value="manager.id">{{ manager.last_name+" "+manager.first_name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Echange</label>
            <select v-model="exchange_id" class="form-control">
              <option value="0"></option>
              <template v-for="exchange in exchanges" :key="exchange.id">
                <option :value="exchange.id">{{ exchange.from.name+" à "+exchange.to.name }}</option>
              </template>
            </select>
          </div>
          <div class="col-md-12 mb-3">
            <label for="">Gain</label>
            <input type="text" v-model="gain" class="form-control">
          </div>
        </div>
        <div class="mt-3" v-if="!loading">
          <input type="submit" value="Enregistrer" class="btn btn-primary">
        </div>
        <div class="mt-3 text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal v-model="doesDeleteOpen" id="modal-center" title="Supprimer un conversion" title-class="font-18" hide-footer>
      <p class="mb-2">
        Voulez-vous vraiment supprimer le conversion <b>{{ conversion?.from?.name }} - {{ conversion?.to?.name }}</b> ?
      </p>
      <div class="hstack mt-2" v-if="!loading">
        <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
        <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
      </div>
      <div class="text-center" v-else>
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>


  </Layout>
</template>
